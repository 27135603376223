import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3rem 0;
`;

export const NextLink = styled(Link)`
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 44px;
    height: 44px;
    background: #de2831;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    &::after{
        content: '';
        display: block;
        height: 25%;
        width: 25%;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%,25%) rotate(45deg);
    }
`;

export const BackgroundImage = styled(Img)`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: -1 !important;
`;

export const BackgroundColor = styled.div`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: -1 !important;
    background: darkgray;
`;

export const PreTitle = styled.span`
    display: block;
    color: ${({ dark }) => (dark ? '#272323' : '#f9f8f3')};
    font-family: "Red Hat Display";
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
`;

export const Title = styled.h3`
    color: ${({ dark }) => (dark ? '#272323' : '#f9f8f3')};
    font-family: "Abril Fatface";
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
`;
