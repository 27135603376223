/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { getRoutesQuery, getLangFromPath } from 'src/utils';
import {
  Wrapper, BackgroundImage, Title, PreTitle, NextLink, BackgroundColor,
} from './styles';

const NextChapter = ({
  preTitle, fixed, dark,
}) => {
  const [content, setContent] = useState({
    title: null,
    navigateTo: '',
  });
  const lang = getLangFromPath();
  const query = getRoutesQuery();
  let { pathname } = useLocation();

  useEffect(() => {
    if (pathname.substr(-1) != '/') pathname += '/';
    const routes = query[lang] ? query[lang].exports.routes : [];
    routes.forEach(({ route }, index) => {
      if (encodeURI(route) === pathname) {
        setContent({
          ...content,
          title: routes[index + 1].name,
          navigateTo: routes[index + 1].route,
        });
      }
    });
  }, [lang]);

  // useEffect(() => {
  //   const splitPath = path.split('/')[1].toUpperCase();
  //   splitPath && setSelectedLocale(splitPath);
  // }, [path]);

  return (
    <>
      {content.title && (
        <Wrapper>
          <NextLink to={content.navigateTo} />
          {fixed ? <BackgroundImage fixed={fixed} /> : <BackgroundColor />}
          <PreTitle dark={dark}>{preTitle}</PreTitle>
          <Title dark={dark}>{content.title}</Title>
        </Wrapper>
      )}
    </>
  );
};

export default NextChapter;
