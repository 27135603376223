/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import image from 'src/assets/images/_shared/bitwa1920.png';

function SEO({ lang, title, description }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        meta={[
          { name: 'description', content: metaDescription },
          // { name: "og:description", content: metaDescription },
        ]}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          // {
          //   property: `og:title`,
          //   content: metaTitle,
          // },
          // {
          //   property: `og:description`,
          //   content: metaDescription,
          // },
          // {
          //   property: `og:type`,
          //   content: `website`,
          // },
          // {
          //   name: `twitter:creator`,
          //   content: site.siteMetadata.author,
          // },
          // {
          //   name: `twitter:title`,
          //   content: metaTitle,
          // },
          // {
          //   name: `twitter:description`,
          //   content: metaDescription,
          // },
        ]}
      />
    </>
  );
}

export default SEO;
