import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    top: 70px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 500ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 800ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${opacity} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const ColumnContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12% 7%;

  & h2 {
    position: relative;
    opacity: 0;
    font-family: "Abril Fatface";
    font-size: 1.875rem !important;
    line-height: 1.875rem;
    text-align: center;
    margin-bottom: 2rem;
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    animation: ${animationTop};
    font-weight: 400;
  }

  & h3 {
    position: relative;
    opacity: 0;
    font-family: "Abril Fatface";
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    animation: ${animationTopSecondary};
    font-weight: 400;
  }

  & > p,
  & > div > p {
    font-family: "Red Hat Display";
    opacity: 0;
    font-size: 0.875rem;
    line-height: 1.5rem;
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    display: inline;
    animation: ${animationTopSecondary};
    font-weight: 400;
  }
  span {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`;
export const Wrapper = styled.div`
  position: relative;
  opacity: 0;
  animation: ${animationBottom};
`;

export const Title = styled.h4`
  ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
  font-family: "Red Hat Display";
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  text-align: center;
`;
