import styled from 'styled-components';
import Img from 'gatsby-image';
import { sidebarWidth } from '../../../../../globals';

export const SlideXLarge = styled.div`
  width: 100vw;
background-color: ${(props) => (props.black ? '#000' : 'transparent')};
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 7%;
  /* padding-left: 55%; */
`;

export const Slide = styled.div`
  width: ${(props) => (props.width ? props.width : '100')}vw;
  background-color: ${(props) => (props.black ? '#000' : 'transparent')};
  height: 100%;
`;

export const SlideLarge = styled.div`
  width: 100vw;
  background-color: ${(props) => (props.black ? '#000' : 'transparent')};
  height: 100%;
`;

export const SlideMLarge = styled.div`
  width: 80vw;
  background-color: ${(props) => (props.black ? '#000' : 'transparent')};
  height: 100%;
`;

export const SlideMedium = styled.div`
  width: 55vw;
  background-color: ${(props) => (props.black ? '#000' : 'transparent')};
  height: 100%;
`;

export const SlideSmall = styled.div`
  width: 30vw;
  background-color: ${(props) => (props.black ? '#000' : 'transparent')};
  height: 100%;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const ResponsiveImage = styled(Img)`
    flex: 100%;
    & img {
      object-fit: contain !important;
    }
`;
