import styled, { css, keyframes } from 'styled-components';

export const MainSliderStyle = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const SliderContainer = styled.div`
  transform: translate3d(${(props) => props.translate}px, 0, 0);
  transition: transform cubic-bezier(0.820, 0.000, 0.195, 1.000) ${(props) => props.transition}s;
  display: flex;
`;

const changeWidth = keyframes`
  from {
    width: 100vw;
  }
  to {
    width: 90.8vw;
  }
`;

export const FirstSlide = styled.div.attrs(() => ({ tabIndex: 0 }))`
  height: 100%;
  width: 100vw;
  animation: ${(props) => (props.biggerThanOne ? css`${changeWidth} 0.6s cubic-bezier(0.820, 0.000, 0.195, 1.000) 1s forwards` : '')} ;
  position: relative;
  overflow: hidden;
  background-color: #000;
`;

export const Slide = styled.div.attrs(() => ({ tabIndex: 0 }))`
  height: 100%;
  width: auto;
  position: relative;
  overflow: hidden;
  background-color: #000;
`;

export const SliderProgresBarContainer = styled.div`
  height: 6px;
  width: 93.4%;
  background-color: #f2efe4;
  position: fixed;
  left: 6.6%;
  top: 0;
  z-index: 99;
`;

export const WholeSiteMask = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgb(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s;
  ${({ active }) => active && `
    z-index: 98;
    visibility: visible;
    opacity: 0.7;
 `}
  ${({ activeLight, active }) => active && activeLight && `
    z-index: 98;
    visibility: visible;
    opacity: 0.5;
 `}
`;

export const SliderProgresBar = styled.div`
  height: 6px;
  transition: width cubic-bezier(0.820, 0.000, 0.195, 1.000) 1s;
  width: ${(props) => props.barWidth}%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #df3242;
`;

const pulsar = keyframes`
  0% {
    transform: scale(1);
    visibility: visible;
  }
  100% {
    transform: scale(2);
    visibility: visible;
    opacity: 0;
  }
`;

export const AnimatedHoverCircle = styled.div`
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: transparent;
  border: 1px solid #ebe6e6;
  visibility: hidden;
  z-index: 100;
  position: absolute;
  border-radius: 100%;
  animation: ${(props) => (!props.firstWasClicked && props.isRight && props.isShowing && props.slideIndex === 0  ? css`${pulsar} cubic-bezier(0.820, 0.000, 0.195, 1.000) 1s infinite` : '')} ;
`;

const showDot = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const slideChange = keyframes`
  0% {
    clip-path: polygon(44% 54%, 45% 0, 45% 0);
  }
  20% {
    clip-path: polygon(44% 54%, 110% 0, 45% 0);
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(360deg);
    clip-path: polygon(44% 54%, 45% 0, 0 0);
  }
  100% {
    clip-path: polygon(44% 54%, 45% 0, 45% 0);
    transform: rotate(360deg);
  }
`;
export const OnSlideChangeAnimation = styled.div`
  width: 3.1rem;
  height: 3.1rem;
  position: absolute;
  clip-path: polygon(44% 54%, 110% 0, 45% 0);
  border: 0.125rem solid #fff;
  border-radius: 100%;
  animation: ${slideChange} 1s cubic-bezier(0.820, 0.000, 0.195, 1.000) forwards;
`;

export const ArrowDot = styled.div`
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: #df3242;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: scale(0);
  animation:  ${showDot} 1.6s cubic-bezier(0.820, 0.000, 0.195, 1.000) 1s forwards;
  ${({ isLeft }) => isLeft && `
    left: 12vw;
 `}
   ${({ isRight }) => isRight && `
     right: 8vw;
  `}
  ${({ isShowing }) => !isShowing && `
    visibility: hidden;
  `}
  z-index: 101;
  &:hover ${AnimatedHoverCircle} {
    transition: 1s cubic-bezier(0.820, 0.000, 0.195, 1.000);
    visibility: visible;
    transform: scale(2);
    opacity: 0;
  }
`;

export const Arrow = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-right: 2px solid #ebe6e6;
  border-bottom: 2px solid #ebe6e6;
  ${({ isLeft }) => isLeft && `
    transform: rotate(135deg);
    margin-left: 0.375rem;
 `};
   ${({ isRight }) => isRight && `
     transform: rotate(-45deg);
     margin-left: -0.375rem;
  `};
`;
