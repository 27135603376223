import styled, { keyframes, css } from 'styled-components';
import Img from 'gatsby-image';

const enterFrames = keyframes`
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.05);
    }
`;

const exitFrames = keyframes`
    0%{
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);
    }
`;

const animateEnter = ({ visible }) => {
  if (visible) {
    return css`
      ${enterFrames} 1s ease-in forwards;
    `;
  }
  return css`
    ${exitFrames} 1s ease-in forwards;
  `;
};

export const PhotoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  background-color: ${({ mask }) => mask};
  background-color: ${({ isDimmed, mask }) => isDimmed && !mask && 'rgba(19, 31, 36,0.6)'};
  transition: background-color 1s ease-in;
`;

export const WrapperBackground = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: ${({ enter }) => (enter ? '120% !important' : '100% !important')};
  z-index: -1;
  transition: transform 0.2s;
  animation: ${({ scale }) => scale && animateEnter};
`;
