import styled from 'styled-components';
import Img from 'gatsby-image';
import '../../../../../static/fonts/brygada.css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BlockContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  background: ${({ bg }) => bg};
  position: relative;
`;

export const ChpaterTitle = styled.h4`
  color: ${({ dark }) => (dark ? '#272323' : '#f9f8f3')} ;
  font-family: "Red Hat Display";
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
  position: relative;
`;

export const LeninTitle = styled.h3`
  color: #0d0207;
  font-family: "Abril Fatface";
  font-size: 7rem;
  white-space: nowrap;
  font-weight: 400;
  position: relative;
  text-align: center;
  margin-top: 16rem;
  opacity: 0.7;
`;

export const ButtonWithBackground = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  padding: 1.2rem 2rem;
  width: 85%;
  margin-left: 5%;
  color: #645d5e;
  font-family: "Red Hat Display";
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #645d5e;
  background: transparent;
  position: relative;
  
  &::after{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f9f8f3;
    font-size: 2rem;
    content: '>';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%,-50%);
    background: #df3242;
    width: 2.625rem;
    height: 2.625rem;
  }
`;

export const Arrow = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-right: 2px solid #ebe6e6;
  border-bottom: 2px solid #ebe6e6;
  transform: ${({ reverse }) => (reverse ? 'rotate(-225deg)' : 'rotate(-45deg)')};
`;

export const ArrowSliderLeft = styled.button`
  position: absolute;
  top: 8.5rem;
  left: -2px;
  width: 30px;
  height: 3.125rem;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #f5f4ee;
  background-color: #342d2e;
  opacity: 0.8;
  color: #f9f8f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowSliderRight = styled.button`
  position: absolute;
  top: 8.5rem;
  right: -2px;
  width: 30px;
  height: 3.125rem;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #f5f4ee;
  background-color: #342d2e;
  opacity: 0.8;
  color: #f9f8f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgFull = styled(Img)`
  width: 100% !important;
  object-fit: fill !important;
`;

export const AvatarFull = styled(Img)`
  max-height: 100vw !important;
  max-width: 100% !important;
  /* margin-left: 15%; */

  &::after{
    content: '${({ title }) => title}';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #e7e5e5;
    font-family: "Abril Fatface";
    font-size: 1.875rem;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }
`;
