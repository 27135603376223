import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hyperlink from '../../_shared/hyperlink/hyperlink';
import { ColumnContent, Wrapper, Title } from './styles';

const TextAnimation = ({
  body, title, containerStyle, titleStyle, images, dark, noMotion,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  return (
    <ColumnContent ref={ref} visible={noMotion ? true : inView} style={containerStyle} dark={dark}>
      {title && <Title style={titleStyle} dark={dark}>{title}</Title>}
      {body && (
        <MDXProvider components={{ Wrapper, Hyperlink }}>
          <MDXRenderer visible={inView} images={images}>{body}</MDXRenderer>
        </MDXProvider>
      )}
    </ColumnContent>
  );
};

export default TextAnimation;
