import React, {
  useState, useEffect, useRef,
} from 'react';
import { gsap } from 'gsap/all';
import rafSchedule from 'raf-schd';
import { Background, Scene } from './styles';
import { isBrowser, isWebpSupported } from '../../../utils/index';

export const ParallaxMordo = ({
  children, background, column, additionalStyling, showOutSite,
}) => {
  const [cords, setCords] = useState({ x: 0, y: 0 });

  const scheduleUpdate = rafSchedule((e) => {
    setCords({ x: e.clientX, y: e.clientY });
  });

  useEffect(() => () => {
    scheduleUpdate.cancel();
  }, [scheduleUpdate]);

  const handleMouseMove = (e) => {
    e.persist();
    scheduleUpdate(e);
  };

  const parallaxElements = React.Children.map(children, (element, index) => (element.props.scene || element.props.mainscene ? React.cloneElement(element, { cords, key: `${index}` }) : React.cloneElement(element, { key: `${index}` })));
  return (
    <Background
      onMouseMove={(e) => handleMouseMove(e)}
      background={background}
      column={column}
      style={additionalStyling}
      showOutSite={showOutSite}
    >
      {parallaxElements}
    </Background>
  );
};

export const Mordo = ({
  children,
  scene,
  cords,
  background,
  mainscene,
  scenelayer,
  move = { x: -30, y: -10 },
  flexi,
  opacity,
  customStyle,
}) => {
  const sceneElement = useRef(null);
  const [webpsupport, setwebpsupport] = useState(false);
  const [isBrowserReady, setIsBrowserReady] = useState(false);


  useEffect(() => {
    setwebpsupport(isWebpSupported());
    setIsBrowserReady(isBrowser());
  }, [isBrowserReady]);

  useEffect(() => {
    function parallaxElement(el, cordsXY) {
      const rect = sceneElement.current.getBoundingClientRect();
      const relX = Math.floor(cordsXY.x - rect.left);
      const relY = Math.floor(cordsXY.y - rect.top);

      gsap.to(el, {
        x: ((relX - rect.width / 2) / rect.width) * move.x, y: ((relY - rect.height / 2) / rect.height) * move.y, ease: 'power2.easeOut',
      });
    }
    if (isBrowserReady) {
      parallaxElement(sceneElement.current, cords);
    }
  }, [cords, move.x, move.y]);
  // TODO: refactor do customowego hooka, który będzie sprawdzał support dla webp i czy jest browser
  // sorry za te nestowania ale musiałem fixować
  // eslint-disable-next-line no-nested-ternary
  const bgImage = (isBrowserReady && background && background.image && webpsupport)
    ? background.image.srcWebp
    : (isBrowserReady && background && background.image)
      ? background.image.src
      : null;

  // TODO: dodanie lazy loading/ window.onload dla komponentu z paralaxą (defer off screen images)
  return (
    <Scene
      scene={scene}
      ref={sceneElement}
      mainscene={mainscene}
      flexi={flexi}
      scenelayer={scenelayer}
      style={customStyle}
      opacity={opacity}
      bgImage={bgImage}
      bgColor={(background && background.color) ? background.color : null}
    >
      {children}
    </Scene>
  );
};
