import React, { useState, useEffect, useRef } from "react"
import ReactTouchEvents from "react-touch-events"
import { useSelector } from "react-redux"
import {
  MainSliderStyle,
  Slide,
  SliderContainer,
  FirstSlide,
  SliderProgresBarContainer,
  SliderProgresBar,
  WholeSiteMask,
  ArrowDot,
  Arrow,
  AnimatedHoverCircle,
  OnSlideChangeAnimation,
} from "./styles"

const navigationWidth = 0.066

const slidesList = (
  slides,
  activeSlide,
  focusGain,
  showMask,
  exceptions,
  makeMovePossible
) =>
  slides.map((slide, index) => {
    const newKey = `mainSlider_${index}`
    const newClass = `mainSliderClass_${index}`
    if (index === 0) {
      return (
        <FirstSlide
          key={newKey}
          className={newClass}
          onAnimationEnd={makeMovePossible}
          onFocus={() => {
            focusGain(index)
          }}
          biggerThanOne={slides.length - 1 > 0}
        >
          <WholeSiteMask active={showMask(index, activeSlide, exceptions)} />
          {slide}
        </FirstSlide>
      )
    }
    return (
      <Slide
        key={newKey}
        className={newClass}
        onFocus={() => {
          focusGain(index)
        }}
      >
        <WholeSiteMask
          active={showMask(index, activeSlide, exceptions)}
          activeLight={index === 1}
        />
        {slide}
      </Slide>
    )
  })

const MainSlider = ({ slides, exceptions }) => {
  const mainSlider = React.createRef()
  
  const [activeSlide, setActiveSlide] = useState(0)
  const [translate, setTranslate] = useState(0);
  const [barWidth, setBarWidth] = useState(slides.length > 1 ? 0 : 100)
  const [madeAnimationLeft, setMadeAnimationLeft] = useState(false)
  const [madeAnimationRight, setMadeAnimationRight] = useState(false)
  const [canMove, setCanMove] = useState(false)
  const [firstWasClicked, setFirstWasClicked] = useState(false)
  const [move, setMove] = useState(true)

  const modalIsOpen = useSelector(store => store.showModal)

  const notCenterFactor = (array, wind, factor) => {
    if (
      array[activeSlide + factor].getBoundingClientRect().width !== wind &&
      activeSlide + factor > 0
    ) {
      return (wind * navigationWidth) / 2
    }
    return null
  }

  const showMask = (index, slide, array) => {
    if (index !== slide) {
      if (array.length && array.some(x => x === index)) {
        return false
      }
      return true
    }
    return false
  }

  const makeMovePossible = () => {
    setCanMove(true)
  }

  const focusGain = index => {
    const { children } = mainSlider.current.children[0]
    if (children && activeSlide !== index && move) {
      setMove(false)
      setActiveSlide(index)
      setTimeout(() => {
        setMove(true)
      }, 1000)
    }
  }
  const moveSlide = () => {
    const { children } = mainSlider.current.children[0]
    let item = mainSlider.current.getBoundingClientRect().width * 0.908

    for (let i = 1; i < activeSlide; i += 1) {
      item += children[i].getBoundingClientRect().width
    }
    const wind = window.innerWidth
    let trans = null
    if (activeSlide === 0) {
      trans = 0
    } else if (activeSlide <= children.length - 2) {
      trans =
        translate -
        item +
        wind / 2 -
        children[activeSlide].getBoundingClientRect().width / 2 -
        mainSlider.current.children[0].getBoundingClientRect().left +
        notCenterFactor(children, wind, 0)
    } else {
      trans =
        translate -
        item +
        (wind - children[activeSlide].getBoundingClientRect().width) -
        mainSlider.current.children[0].getBoundingClientRect().left
    }
    setTranslate(trans)
    setBarWidth((100 * activeSlide) / (children.length - 1))
  }
  useEffect(() => {
    moveSlide()
  }, [activeSlide])

  const onAnimationEndLeft = () => {
    setMadeAnimationLeft(false)
    setMove(true)
  }

  const onAnimationEndRight = () => {
    setMadeAnimationRight(false)
    setMove(true)
  }

  const nextSlide = array => {
    if (!firstWasClicked) {
      setFirstWasClicked(true)
    }
    if (array.length - 1 > activeSlide && move) {
      setMove(false)
      setActiveSlide(activeSlide + 1)
      setMadeAnimationLeft(true)
      setMadeAnimationRight(false)
    }
  }

  const prevSlide = () => {
    if (activeSlide > 0 && move) {
      setMove(false)
      setActiveSlide(activeSlide - 1)
      setMadeAnimationLeft(false)
      setMadeAnimationRight(true)
    }
  }
  const handleKeyPress = event => {
    if (mainSlider.current && !modalIsOpen) {
      const { children } = mainSlider.current.children[0]
      if (event.key === "ArrowRight") {
        nextSlide(children)
      } else if (event.key === "ArrowLeft") {
        prevSlide()
      }
    }
  }

  const handleResize = () => {
    if (mainSlider.current && canMove) {
      moveSlide()
    }
  }

  const handleSlideChange = direction => {
    const { children } = mainSlider.current.children[0]
    if (direction === "left") {
      nextSlide(children)
    } else if (direction === "right") {
      prevSlide()
    }
  }

  useEffect(() => {
    /* eslint-disable no-undef */
    window.addEventListener("resize", handleResize)
    /* eslint-disable no-undef */
    window.addEventListener("keydown", handleKeyPress)
    return () => {
      /* eslint-disable no-undef */
      window.removeEventListener("resize", handleResize)
      /* eslint-disable no-undef */
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <>
      <SliderProgresBarContainer>
        <SliderProgresBar translate={translate} barWidth={barWidth} />
      </SliderProgresBarContainer>
      <ArrowDot
        isLeft
        onClick={() => handleSlideChange("right")}
        isShowing={activeSlide !== 0}
      >
        {madeAnimationRight && (
          <OnSlideChangeAnimation onAnimationEnd={onAnimationEndRight} />
        )}
        <AnimatedHoverCircle />
        <Arrow isLeft />
      </ArrowDot>
      <ArrowDot
        isRight
        onClick={() => handleSlideChange("left")}
        isShowing={
          slides.length > 1 && activeSlide !== slides.length - 1 && canMove
        }
      >
        {madeAnimationLeft && (
          <OnSlideChangeAnimation onAnimationEnd={onAnimationEndLeft} />
        )}
        <AnimatedHoverCircle
          slideIndex={activeSlide}
          firstWasClicked={firstWasClicked}
          isRight
          isShowing={slides.length > 1}
        />
        <Arrow isRight />
      </ArrowDot>
      <ReactTouchEvents onSwipe={handleSlideChange}>
        <MainSliderStyle ref={mainSlider}>
          <SliderContainer translate={translate} transition={1}>
            {slidesList(
              slides,
              activeSlide,
              focusGain,
              showMask,
              exceptions,
              makeMovePossible
            )}
          </SliderContainer>
        </MainSliderStyle>
      </ReactTouchEvents>
    </>
  )
}
export default MainSlider
