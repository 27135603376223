import styled, { css } from 'styled-components';

export const Background = styled.div`
    display: flex;
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    height: 100vh;
    width: 100%;
    flex-wrap: wrap;
    ${({ background }) => background
        && css`
    background-image:url(${background.image || ''});
    background-color:${background.color || 'inherit'};
    `}
    ${({ column }) => column
        && css`
    flex-direction: column;
    `}
    ${({ showOutSite }) => showOutSite
        && css`
    overflow: visible;
    `}
    `;

export const Scene = styled.div`
    z-index: ${({ scene }) => scene * 10 || 0};
    ${({ opacity }) => opacity
        && css`
    opacity:${opacity || 1};
    `}
    ${({ bgImage }) => bgImage
        && css`
    background-image:url(${bgImage || ''});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    `}
    ${({ bgColor }) => bgColor
        && css`
    background-color:${bgColor || 'inherit'};
    position: absolute;
    left: -2%;
    top: -2%;
    bottom: -2%;
    right: -2%;
    height: 104%;
    `}
    ${({ bgImage, mainscene }) => bgImage && mainscene
        && css`
    position: absolute;
    left: -2%;
    top: -2%;
    bottom: -2%;
    right: -2%;
    height: 104%;
    `}
    ${({ bgImage, scenelayer }) => bgImage && scenelayer
        && css`
    position: absolute;
    left: ${scenelayer.fromLeft || '-2%'};
    top: -2%;
    bottom: ${scenelayer.fromBottom || '-2%'};
    right: -2%;
    background-size: ${scenelayer.size || '100%'};
    background-position: ${scenelayer.position || 'bottom center'};
    `}
    ${({ flexi }) => flexi
        && css`
    display: flex;
    flex: ${flexi.flex || '0 0 100%'};
    flex-direction: ${flexi.column ? 'column' : 'row'};
    align-self: ${flexi.alignSelf || 'auto'};
    align-items: ${flexi.alignItems || 'normal'};
    `}
`;
