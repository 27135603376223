import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { PhotoWrapper, WrapperBackground } from './styles';

const BackgroundPhoto = ({
  fixed, children, scale, enter, mask, containerStyle, wrapperStyle,
}) => {
  const wrapperRef = useRef(null);
  const [isDimmed, setDimmed] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const onScroll = (e) => {
      if (enter) {
        requestAnimationFrame(() => {
          const topWrapper = wrapperRef.current.imageRef.current.getBoundingClientRect().top;
          wrapperRef.current.imageRef.current.style.transform = `translate3d(0,${-(topWrapper / 50)}%,0)`;
          if ((-(topWrapper / 50)) > 0) {
            setDimmed(true);
          } else {
            setDimmed(false);
          }
        });
      }
    };
    if (inView) {
      window.addEventListener('scroll', onScroll);
    }
    if (!inView) {
      window.removeEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, [inView]);

  return (
    <PhotoWrapper
      ref={ref}
      mask={mask}
      isDimmed={isDimmed}
      style={containerStyle}
    >
      <WrapperBackground
        ref={wrapperRef}
        fixed={fixed}
        visible={inView}
        scale={scale}
        enter={enter}
        style={wrapperStyle}
      />
      {children}
    </PhotoWrapper>
  );
};

export default BackgroundPhoto;
